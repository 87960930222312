import React from 'react';
import './styles.css';

import {FaInstagram,FaFacebook } from 'react-icons/fa';

import logo from '../../assets/logo.png';

export default function Home() {

  return(
    <div className="container">
      <img src={logo} alt="logo" className="logo"/>
      <h1 className="title">
        Estamos em manutenção.
      </h1>
      <footer className="icons">
        <a target="blank"href="https://www.instagram.com/postolopes_/">
          <FaInstagram  size="30"color="#f2f2f2"/>
        </a>
        <a target="blank" href="https://www.facebook.com/POSTOLOPES">
          <FaFacebook size="30"color="#f2f2f2"/>
        </a>

      </footer>
    </div>
  );
}
